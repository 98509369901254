import { useTranslation } from 'react-i18next';
import './index.css'
import { Helmet } from "react-helmet";
import { NotesList } from './lib';
import { NotesListItem } from '../../features/NotesListItem';

export const NotesListPage = () => {
  const { t } = useTranslation();

  return (
    <div className='mainContainer notesList_container'>
      
      <Helmet>
        <title>Заметки о веб-разработке</title>
        <meta name="description" content="Читайте полезные статьи и заметки от LKeyStudio о веб-разработке, автоматизации бизнес-процессов и современных технологиях." />
        <meta name="keywords" content="блог, веб-разработка, статьи о технологиях, автоматизация бизнеса, LKeyStudio" />
        <meta name="author" content="LKeyStudio" />
        <meta name="robots" content="noindex"/>
      </Helmet>

      <h1>{t('notes')}</h1>
      {NotesList.map((item, ind) =>
        <NotesListItem item={item} key={ind}/>
      )}
    </div>
  )
};
