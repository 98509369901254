import React, { useRef } from 'react';
import './index.css';

const HorizontalScroll = ({ items }) => {
  const scrollContainerRef = useRef(null);

  return (
    <div className="horizontal-scroll">
      <div className="scroll-container" ref={scrollContainerRef}>
        {items.map((item, index) => (
          <div key={index} className="scroll-item">
            <img src={item.icon} alt={item.name} loading="lazy"/>
            <span>{item.name}</span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default HorizontalScroll;
