import { useTranslation } from 'react-i18next';
import { ExpandedDiv } from '../Services/ui/ExpandedDiv';
import './index.css';

export const Advantages = () => {
    const { t } = useTranslation();

    return (
        <div className='mainContainer advantages_container' id='advantages'>
            <h1 >{t('advantages')}</h1>
            <ExpandedDiv
                service={t('Adv1')}
                text={t('Adv1Desc')}
                biggerTitle={true}
            />
            <ExpandedDiv
                service={t('Adv2')}
                text={t('Adv2Desc')}
                biggerTitle={true}
            />
            <ExpandedDiv
                service={t('Adv3')}
                text={t('Adv3Desc')}
                biggerTitle={true}
            />
            <ExpandedDiv
                service={t('Adv4')}
                text={t('Adv4Desc')}
                biggerTitle={true}
            />
        </div>
    );
};
