import { useState } from 'react'; 
import { useTranslation } from 'react-i18next'; 
import './index.css'; 
import { ExpandedDiv } from './ExpandedDiv'; 
import CompPep from '../../../shared/images/LkeyPC.svg'
 
export const Services = () => { 
  const { t } = useTranslation(); 
  const [activeTab, setActiveTab] = useState('web-dev'); 
 
  return ( 
    <div className='mainContainer services_container' id='services'> 
      <h1 >{t('ourServices')}</h1> 
      <div className='services_innerContainer'> 
        <div className='services_nav'> 
          <button className={`services_navButton ${activeTab === 'web-dev' && 'active'}`} onClick={()=>setActiveTab('web-dev')}> 
            {t('web-dev')} 
          </button> 
          <button className={`services_navButton ${activeTab === 'evolution' && 'active'}`}  onClick={()=>setActiveTab('evolution')}> 
            {t('evolution')} 
          </button> 
          <img alt='computer pep' src={CompPep} loading="lazy"/>
        </div> 
        {activeTab === 'web-dev' ? ( 
          <div className='services_infoContainer'> 
            <div className='text_container'> 
              <p 
                dangerouslySetInnerHTML={{ 
                  __html: t('web-dev-Info') 
                }} 
              /> 
            </div> 
            <ExpandedDiv 
              service={t('moduleDev')} 
              price={t('fromModuleDev')} 
              text={t('moduleDevDesc')} 
            /> 
            <ExpandedDiv 
              service={t('onepageLanding')} 
              price={t('fromOnepageLanding')} 
              text={t('onepageLandingDesc')} 
            /> 
            <ExpandedDiv 
              service={t('multipageWeb')} 
              price={t('fromMultipageWeb')} 
              text={t('multipageWebDesc')} 
            /> 
            <ExpandedDiv 
              service={t('webCatalog')} 
              price={t('fromWebCatalog')} 
              text={t('webCatalogDesc')} 
            /> 
            <ExpandedDiv  
              service={t('webShop')}  
              price={t('fromWebShop')}  
              text={t('webShopDesc')}  
            /> 
          </div> 
        ) : ( 
          <div className='services_infoContainer'> 
            <div className='text_container'> 
              <p 
                dangerouslySetInnerHTML={{ 
                  __html: t('evolutionInfo') 
                }} 
              /> 
            </div> 
            <ExpandedDiv 
              service={t('webSup')} 
              price={t('webSupFrom')} 
              text={t('webSupDesc')} 
            /> 
            <ExpandedDiv 
              service={t('marketplaceSup')} 
              price={t('marketplaceSupFrom')} 
              text={t('marketplaceSupDesc')} 
            /> 
            <ExpandedDiv 
              service={t('webAppDev')} 
              price={t('webAppDevFrom')} 
              text={t('webAppDevDesc')} 
            /> 
            <ExpandedDiv  
              service={t('tgBot')}  
              price={t('tgBotFrom')}  
              text={t('tgBotDesc')}  
            /> 
          </div> 
        )} 
      </div> 
    </div> 
  ); 
};
