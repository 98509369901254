import { useEffect, useState } from 'react';
import './index.css';
import { StepCard } from './StepCard';
import IconOne from '../../shared/images/one.svg';
import IconTwo from '../../shared/images/two.svg';
import IconThree from '../../shared/images/three.svg';
import IconFour from '../../shared/images/four.svg';
import Line from '../../shared/images/line.svg';

export const PricePageContent = ({ pepIcon, firstText, info }) => {
  const [phoneVersion, setPhoneVersion] = useState(window.innerWidth < 1280);

  useEffect(() => {
    const handleResize = () => {
      const isPhone = window.innerWidth < 1280;
      if (isPhone !== phoneVersion) {
        setPhoneVersion(isPhone);
        window.location.reload();
      }
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [phoneVersion]);

  return (
    <div className='pricePageContent_container'>
      <div className='pricePageContent_mainDescription'>
        <div className='pricePageContent_mainDescription_text'>
          <p
            dangerouslySetInnerHTML={{
              __html: firstText
            }}
          />
        </div>
        <img alt='price man pep' src={pepIcon} loading="lazy"/>
      </div>
      {phoneVersion ? (
        <div className='pricePageContent_innerContainer'>
          <StepCard title={info[0]} text={info[1]} icon={IconOne} />
          <StepCard title={info[2]} text={info[3]} icon={IconTwo} />
          <StepCard title={info[4]} text={info[5]} icon={IconThree} />
          <StepCard title={info[6]} text={info[7]} icon={IconFour} />
        </div>
      ) : (
        <div className='pricePageContent_innerContainer'>
          <div className='pricePageContent_innerContainer_left'>
            <StepCard title={info[0]} text={info[1]} icon={IconOne} />
            <StepCard title={info[4]} text={info[5]} icon={IconThree} />
          </div>
          <div className='pricePageContent_innerContainer_middle'>
            <img alt='beautiful line' src={Line} loading="lazy"/>
          </div>
          <div className='pricePageContent_innerContainer_right'>
            <StepCard title={info[2]} text={info[3]} icon={IconTwo} />
            <StepCard title={info[6]} text={info[7]} icon={IconFour} />
          </div>
        </div>
      )}
    </div>
  );
};
