import './index.css'
import { MainBanner } from '../../features/MainBanner';
import { Services } from '../../features/Services';
import { Price } from '../../features/Price';
import { Stack } from '../../features/Stack';
import { Advantages } from '../../features/Advantages';
import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import { Helmet } from "react-helmet";
export const HomePage = () => {
    const location = useLocation();

    useEffect(() => {
        if (location.hash) {
          const elementId = location.hash.substring(1); // Убираем символ #
          const element = document.getElementById(elementId);
    
          if (element) {
            const headerOffset = 100; // Высота хедера
            const elementPosition = element.getBoundingClientRect().top;
            const offsetPosition = elementPosition + window.scrollY - headerOffset;
    
            window.scrollTo({
              top: offsetPosition,
              behavior: 'smooth',
            });
          }
        }
      }, [location]); // Срабатывает при изменении URL

    const jsonLdData = {
      "@context": "https://schema.org",
      "@type": "WebSite",
      "url": "https://lkey-studio.ru/",
      "name": "LKey Studio | Разработка сайтов и онлайн-решений для бизнеса",
      "alternateName": ["LKeyStudio", "LKey-Studio", "LKey Studio"],
      "description": "Ваши идеи — наша реализация: создаём сайты, приложения и чат-ботов, помогаем настроить маркетплейсы и обеспечиваем поддержку вашего бизнеса.",
      "publisher": {
        "@type": "Organization",
        "name": "LKey Studio",
        "logo": "https://lkey-studio.ru/mainImage.png"
      },
      "contactPoint": {
        "@type": "ContactPoint",
        "contactType": "Customer Support",
        "email": "ask@lkeystudio.ru",
        "url": "https://t.me/lkeystudio_bot"
      },
      "mainEntity": {
        "@type": "ItemList",
        "itemListElement": [
          {
            "@type": "Service",
            "name": "Веб-разработка",
            "description": "Создание сайтов различной сложности: одностраничные лендинги, многостраничные сайты, интернет-магазины.",
            "serviceType": "Web Development"
          },
          {
            "@type": "Service",
            "name": "Развитие и автоматизация",
            "description": "Поддержка сайтов, автоматизация бизнес-процессов, работа с маркетплейсами и разработка ботов.",
            "serviceType": "Business Automation"
          },
          {
            "@type": "Service",
            "name": "Стоимость",
            "description": "Два варианта оплаты: фиксированная стоимость для четко спланированных проектов и почасовая ставка для гибких решений.",
            "serviceType": "Pricing"
          }
        ]
      },
      "about": {
        "@type": "Thing",
        "name": "Преимущества",
        "description": "Высокая скорость работы, индивидуальный подход к каждому клиенту, гибкость в разработке и поддержке проектов."
      },
      "knowsAbout": [
        {
          "@type": "Thing",
          "name": "Технологический стек",
          "description": "Мы используем современные технологии для разработки: React, Node.js, MongoDB, PostgreSQL, Docker, и другие."
        }
      ]
    };
      
  
    return (
        <>
            <Helmet>
              <meta property="og:title" content="LKey Studio | Разработка сайтов и онлайн-решений для бизнеса" />
              <meta property="og:description" content="Ваши идеи — наша реализация: создаём сайты, приложения и чат-ботов, помогаем настроить маркетплейсы и обеспечиваем поддержку вашего бизнеса. Мы предлагаем высокую скорость работы, гибкость и современный стек технологий: React, Node.js, MongoDB и многое другое." />
              <meta property="og:url" content="https://lkey-studio.ru/" />
              <meta property="og:type" content="website" />
              <meta property="og:image" content="https://lkey-studio.ru/mainImage.png" />
              <meta property="og:site_name" content="LKey Studio" />
              <meta property="og:locale" content="ru_RU" />
              <meta property="og:updated_time" content="2024-12-05T00:00:00+03:00" />
              <script type="application/ld+json">{JSON.stringify(jsonLdData)}</script>
            </Helmet>
            <MainBanner />
            <Services />
            <Advantages />
            <Price />
            <Stack />
        </>
    )
};
