import './index.css';
import { useTranslation } from 'react-i18next';
import { Form } from './Form';
import { Info } from './Info';

export const Footer = ({openPopup}) => {
  const { t } = useTranslation();

  return (
    <div className='footer_container' id='contact'>
      <div className='mainContainer' itemscope itemtype="http://schema.org/Organization">
        <h1 >{t('contacts')}</h1>
        <p className='containerSubTitle'>{t('callUs')}</p>
        <div className='footer_innerContainer'>
          <Form openPopup={() => openPopup()}/>
          <Info />
        </div>
      </div>
    </div>
  );
};