
import React from "react";
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Helmet } from "react-helmet";
import PepsImg from '../../shared/images/peps.svg'
import "./index.css";


export const SeoDescPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const jsonLdData = {
    "@context": "https://schema.org",
    "@type": "WebPage",
    "url": "https://lkey-studio.ru/notes/seo-optimization-tips",
    "name": "Советы по SEO-оптимизации: Как улучшить видимость сайта",
    "description": "Узнайте, как улучшить видимость вашего сайта в поисковых системах. Советы по SEO-оптимизации: работа с мета-тегами, структурированными данными, улучшением скорости загрузки и мобильной адаптивностью.",
    "mainEntity": {
      "@type": "ItemList",
      "itemListElement": [
        {
          "@type": "Thing",
          "name": "Работа с мета-тегами",
          "description": "Как правильно настроить мета-теги для улучшения ранжирования сайта."
        },
        {
          "@type": "Thing",
          "name": "Использование структурированных данных",
          "description": "Почему JSON-LD важен для SEO и как его внедрить на сайте."
        },
        {
          "@type": "Thing",
          "name": "Ускорение загрузки сайта",
          "description": "Советы по оптимизации скорости загрузки для повышения показателя Core Web Vitals."
        },
        {
          "@type": "Thing",
          "name": "Мобильная адаптивность",
          "description": "Как проверить и улучшить мобильную версию сайта для лучшего ранжирования."
        }
      ]
    }
  };
  

  return (
    <div className="mainContainer comparison">

      <Helmet>
        <title>SEO-оптимизация: Советы по улучшению видимости сайта</title>
        <meta name="description" content="Лучшие советы по SEO-оптимизации для повышения видимости сайта: настройка мета-тегов, использование структурированных данных, ускорение загрузки и мобильная адаптивность." />
        <meta name="keywords" content="SEO-оптимизация, советы по SEO, видимость сайта, мета-теги, ускорение сайта, структурированные данные, мобильная адаптивность" />
        <meta name="author" content="LKeyStudio" />
        <script type="application/ld+json">{JSON.stringify(jsonLdData)}</script>

        <meta property="og:title" content="SEO-оптимизация: Советы по улучшению видимости сайта" />
        <meta property="og:description" content="Лучшие советы по SEO-оптимизации для повышения видимости сайта: настройка мета-тегов, использование структурированных данных, ускорение загрузки и мобильная адаптивность." />
        <meta property="og:url" content="https://lkey-studio.ru/notes/seo-optimization-tips" />
        <meta property="og:type" content="article" />
        <meta property="og:image" content="https://lkey-studio.ru/mainImage.png" />
        <meta property="og:site_name" content="LKeyStudio" />
        <meta property="og:locale" content="ru_RU" />
        <meta property="og:updated_time" content="2024-12-05T00:00:00+03:00" />
      </Helmet>

      <img className = "comparison-img"alt='peps decor' src={PepsImg} loading="lazy"/>
      <h1
        dangerouslySetInnerHTML={{
          __html: t('seoOpt'),
        }}
      ></h1>

      <h2>{t('wayToTop')}</h2>
      <p>{t('wayToTopText')}</p>
      
      <h2>{t('whySeo')}</h2>
      <p>{t('whySeoText')}</p>
      
      <h2>{t('seoAfterDev')}</h2>
      <p        
        dangerouslySetInnerHTML={{
          __html: t('seoAfterDevText'),
        }}
      ></p>

      <h2>{t('summary')}</h2>
      <p
        dangerouslySetInnerHTML={{
          __html: t('seoConc'),
        }}
      ></p>
      <button className='colorfull-button comparisonNote_button' onClick={() => { navigate('/')}}>Заказать сайт</button>
    </div>
  );
};

