import React, { useState } from "react";
import "./index.css";

export const Cookies = () => {
  const [isVisible, setIsVisible] = useState(
    !localStorage.getItem("cookieConsent")
  );

  const handleAccept = () => {
    localStorage.setItem("cookieConsent", "true");
    setIsVisible(false);
  };

  if (!isVisible) return null;

  return (
    <div className="cookies-container">
      <div className="cookies-content">
        <p>
        Мы используем файлы cookie и рекомендательные технологии. 
        Вы даете {''}
        <a className="docsLink" target="_blank"  rel="noreferrer" href="https://disk.yandex.ru/i/wxIt4k2Ap_pKaQ">согласие на обработку персональных данных</a> 
        {''}  и подтверждаете, что ознакомлены с {''} 
        <a className="docsLink" target="_blank"  rel="noreferrer" href="https://disk.yandex.ru/i/gDNyariBXmn-ew">Политикой конфиденциальности.</a>
        </p>
        <button onClick={handleAccept} className="cookies-button">
          Ознакомлен и согласен
        </button>
      </div>
    </div>
  );
};
