import { useNavigate } from 'react-router-dom';
import './index.css';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';

export const NotesListItem = ({ item }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [phoneVersion, setPhoneVersion] = useState(window.innerWidth < 800);

  useEffect(() => {
    const handleResize = () => {
      const isPhone = window.innerWidth < 800;
      if (isPhone !== phoneVersion) {
        setPhoneVersion(isPhone);
        window.location.reload();
      }
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [phoneVersion]);

  return (
    <div className='notesListItem_container'>
      <h2>{t(item.title)}</h2>
      <div className='notesListItem_inner_container'>
        {!phoneVersion &&
          <div className='notesListItem_img_container'>
            <img src={item.img} alt='note item img' />
          </div>
        }
        <div className='notesListItem_info_container'>
          <p>{t(item.description)}</p>
          <button className='colorfull-button' onClick={() => navigate(`/notes/${item.id}`)}>{t('learnMore')}</button>
        </div>
      </div>
    </div>
  );
};
