import './index.css'
import { useTranslation } from 'react-i18next';
import TextImg from '../../shared/images/mainText.svg'
import PepsImg from '../../shared/images/peps.svg'

export const MainBanner = () => {
    const { t } = useTranslation();
  
    return (
        <div className='mainBanner_container'>
            <div className='mainBanner_textContainer'>
                <p>{t('yourIdeas')}</p>
                <p>{t('ourProjects')}</p>
                <img alt='text decor' src={TextImg}/>
            </div>
            <img className='mainBanner_img' alt='peps decor' src={PepsImg}/>
        </div>
    )
};
