import './index.css';
// import { ReactComponent as WA } from '../../../../shared/images/whatsapp.svg';
import { ReactComponent as TG } from '../../../../shared/images/telegram.svg';

export const Form = () => {

  // const handleSubmitWA = () => {
  //   const phone = '79940199114'; // Замените на номер WhatsApp, куда будут отправляться сообщения
  //   const message = encodeURIComponent(`Добрый день. Интересует:`);
  //   const whatsappUrl = `https://wa.me/${phone}?text=${message}`;
  //   window.open(whatsappUrl, '_blank');
  // };

  const handleSubmitTG = () => {
    const message = `Добрый день. Интересует:`;
    const telegramUrl = `https://t.me/lkeystudio_bot?text=${message}`;
    window.open(telegramUrl, '_blank');
  };

  return (
    <>
      <div className='form_container'>
        <div className='form_section'>
          <div className='form_buttons'>
            <div className='social_buttons'>
              {/* <button className='social_button' onClick={() => handleSubmitWA()}>
                <WA />
              </button> */}
              <button className='social_button' onClick={() => handleSubmitTG()}>
                <TG itemprop="sameAs"/>
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};