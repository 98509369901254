
import React from "react";
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Helmet } from "react-helmet";
import PepsImg from '../../shared/images/peps.svg'
import "./index.css";


export const WebVsConstructorPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const jsonLdData = {
    "@context": "https://schema.org",
    "@type": "WebPage",
    "url": "https://lkey-studio.ru/notes/dev-vs-constructor",
    "name": "Сравнение: Сайт на конструкторе vs. Программируемый сайт",
    "description": "Узнайте, чем отличается сайт на конструкторе от программируемого сайта. Подробное сравнение времени разработки, гибкости дизайна и функциональности.",
    "mainEntity": {
      "@type": "ItemList",
      "itemListElement": [
        {
          "@type": "Thing",
          "name": "Сайт на конструкторе",
          "description": "Быстрое и экономичное решение с использованием готовых шаблонов. Подходит для лендингов и небольших сайтов.",
          "additionalProperty": [
            {
              "@type": "PropertyValue",
              "name": "Время разработки",
              "value": "3-14 дней"
            },
            {
              "@type": "PropertyValue",
              "name": "Стоимость",
              "value": "до 50 000₽"
            },
            {
              "@type": "PropertyValue",
              "name": "Гибкость дизайна",
              "value": "Ограничена готовыми шаблонами"
            }
          ]
        },
        {
          "@type": "Thing",
          "name": "Программируемый сайт",
          "description": "Уникальное решение с высокой скоростью загрузки, масштабируемостью и полной кастомизацией.",
          "additionalProperty": [
            {
              "@type": "PropertyValue",
              "name": "Время разработки",
              "value": "1-3 месяца"
            },
            {
              "@type": "PropertyValue",
              "name": "Стоимость",
              "value": "от 150 000₽"
            },
            {
              "@type": "PropertyValue",
              "name": "Гибкость дизайна",
              "value": "Полная свобода дизайна"
            }
          ]
        }
      ]
    }
  };
  
  
  return (
    <div className="mainContainer comparison">
      <Helmet>
        <title>Сравнение сайтов: Конструктор vs. Программируемый сайт</title>
        <meta name="description" content="Сравните сайты на конструкторах и программируемые решения: время разработки, стоимость, гибкость дизайна и функциональность. Узнайте, что лучше для вашего бизнеса." />
        <meta name="keywords" content="сравнение сайта на конструкторе, программируемый сайт, веб-разработка, разработка сайтов, преимущества программируемых сайтов" />
        <meta name="author" content="LKeyStudio" />

        <meta property="og:title" content="Сравнение сайтов: Конструктор vs. Программируемый сайт" />
        <meta property="og:description" content="Сравните сайты на конструкторах и программируемые решения: время разработки, стоимость, гибкость дизайна и функциональность. Узнайте, что лучше для вашего бизнеса." />
        <meta property="og:url" content="https://lkey-studio.ru/notes/dev-vs-constructor" />
        <meta property="og:type" content="article" />
        <meta property="og:image" content="https://lkey-studio.ru/mainImage.png" />
        <meta property="og:site_name" content="LKeyStudio" />
        <meta property="og:locale" content="ru_RU" />
        <meta property="og:updated_time" content="2024-12-05T00:00:00+03:00" />
      
        <script type="application/ld+json">{JSON.stringify(jsonLdData)}</script>
      </Helmet>
      
      <img className = "comparison-img"alt='peps decor' src={PepsImg} loading="lazy"/>
      <h1
        dangerouslySetInnerHTML={{
          __html: t('whatIsTheDifference'),
        }}
      ></h1>
      <h2>{t('introductionConstructor')}</h2>
      <p>{t('constructorDesc')}</p>
      <h2>{t('introductionProgrammable')}</h2>
      <p>{t('programmableDesc')}</p>
      
      <h2>{t('detailedComparison')}</h2>

      <h3>{t('devTime')}</h3>
      <p>{t('devTimeDesc')}</p>

      <h3>{t('speed')}</h3>
      <p 
        dangerouslySetInnerHTML={{ 
          __html: t('speedDesc') 
        }} 
      /> 
      <button className="button-text-green comparisonBut" onClick={() => { navigate('/note/seo-optimization-tips');  window.scrollTo(0, 0)}}>{t('speedMoreButton')}</button>

      <h3>{t('design')}</h3>
      <p>{t('designDesc')}</p>

      <h3>{t('priceComp')}</h3>
      <p>{t('priceCompDesc')}</p>

      <h3>{t('functions')}</h3>
      <p>{t('functionsDesc')}</p>

      <h3>{t('support')}</h3>
      <p>{t('supportDesc')}</p>

      <h2>{t('summary')}</h2>
      <p
              dangerouslySetInnerHTML={{
                __html: t('comparisonConclusion'),
              }}
      ></p>
      <button className='colorfull-button comparisonNote_button' onClick={() => { navigate('/')}}>Заказать сайт</button>
    </div>
  );
};

