export const NotesList = [
    {
        title: 'note1',
        img: new URL('../../shared/images/webVsConst.png', import.meta.url).toString(),
        description: 'note1Desc',
        id: 'dev-vs-constructor'
    },
    {
        title: 'note2',
        img: new URL('../../shared/images/seo.png', import.meta.url).toString(),
        description: 'note2Desc',
        id: 'seo-optimization-tips'
    }
]