import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import translationEN from "./language/translationEN.json";
import translationRU from "./language/translationRU.json";
import translationZH from "./language/translationZH.json"

const resources = {
  en: {
    translation: translationEN
  },
  ru: {
    translation: translationRU
  },
  zh: {
    translation: translationZH
  }
};

i18n
  .use(Backend)

  .use(LanguageDetector)

  .use(initReactI18next)

  .init({
    resources,
    fallbackLng: "ru",
    debug: true,
    lng: "ru",
    interpolation: {
      escapeValue: false // not needed for react as it escapes by default
    }
  });

export default i18n;
