import { useTranslation } from 'react-i18next';
import './index.css';
import HorizontalScroll from '../../Scroll';

const items = [
  { name: 'HTML5', icon: 'https://upload.wikimedia.org/wikipedia/commons/3/38/HTML5_Badge.svg' },
  {
    name: 'CSS3',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/d/d5/CSS3_logo_and_wordmark.svg'
  },
  { name: 'React', icon: 'https://upload.wikimedia.org/wikipedia/commons/a/a7/React-icon.svg' },
  {
    name: 'Python',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/c/c3/Python-logo-notext.svg'
  },
  {
    name: 'Djngo',
    icon: 'https://www.svgrepo.com/show/353657/django-icon.svg'
  },
  {
    name: 'PostgreSQL',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/2/29/Postgresql_elephant.svg'
  }
];

export const Stack = () => {
  const { t } = useTranslation();

  return (
    <div className='mainContainer stack_container' id='stack'>
      <h1 >{t('techStack')}</h1>
      <HorizontalScroll items={items} />
    </div>
  );
};
