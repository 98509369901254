import { useTranslation } from 'react-i18next';
import './index.css'
import { PricePageContent } from '../../features/PricePage';
import PriceManPep from '../../shared/images/priceManPep.svg'
import PriceGirlPep from '../../shared/images/priceGirlPep.svg'
import { Helmet } from "react-helmet";
import { useNavigate, useParams } from 'react-router-dom';

export const PricePage = () => {
  const { t } = useTranslation();
  const { priceType } = useParams();
  const navigate = useNavigate();

  const jsonLdData = {
    "@context": "https://schema.org",
    "@type": "WebPage",
    "name": "Стоимость разработки: фиксированная и почасовая оплата",
    "description": "Выберите подходящую модель оплаты для вашего проекта: фиксированная стоимость или почасовая ставка. Узнайте, как мы планируем и реализуем проекты, чтобы достичь предсказуемого результата в установленные сроки.",
    "mainEntity": {
      "@type": "ItemList",
      "itemListElement": [
        {
          "@type": "Thing",
          "name": "Фиксированная стоимость",
          "description": "Четкое планирование и фиксированная цена для достижения предсказуемого результата в установленные сроки.",
          "additionalProperty": [
            {
              "@type": "PropertyValue",
              "name": "Примерная стоимость",
              "value": "От 20 000₽ за Техническое задание"
            },
            {
              "@type": "PropertyValue",
              "name": "Особенности",
              "value": "Фиксация стоимости и сроков после утверждения ТЗ"
            },
            {
              "@type": "PropertyValue",
              "name": "Когда подходит",
              "value": "Для проектов с четко определенными задачами и сроками"
            }
          ]
        },
        {
          "@type": "Thing",
          "name": "Почасовая ставка",
          "description": "Гибкий подход для долгосрочных и непродсказуемых проектов, с оплатой по времени работы.",
          "additionalProperty": [
            {
              "@type": "PropertyValue",
              "name": "Стоимость",
              "value": "1000₽ за час одного специалиста"
            },
            {
              "@type": "PropertyValue",
              "name": "Особенности",
              "value": "Прозрачное управление временем и затратами"
            },
            {
              "@type": "PropertyValue",
              "name": "Когда подходит",
              "value": "Для долгосрочных проектов с изменяемыми требованиями"
            }
          ]
        }
      ]
    }
  };


  return (
    <div className='mainContainer price_container' id='price'>
      <Helmet>
        <title> {priceType === 'priceFixOption'
          ? 'LkeyStudio | Фиксированная стоимость, сайты под ключ'
          : 'LkeyStudio | Веб-разработка сайта с почасовой оплатой'}
        </title>
        <meta
        name="description"
        content={
          priceType === 'fixOption'
            ? 'Создайте идеальный сайт с LkeyStudio! Мы предлагаем фиксированную стоимость и полный контроль всех этапов разработки. Надёжность, чёткие сроки и предсказуемый результат — всё, что нужно для успеха вашего бизнеса.'
            : 'Разрабатывайте сайты с LkeyStudio на ваших условиях! Почасовая оплата — это гибкость, прозрачность и возможность оплачивать только за результат. Оцените наш профессионализм и индивидуальный подход.'
        }
      />
      <script type="application/ld+json">{JSON.stringify(jsonLdData)}</script>
        <meta
          name="keywords"
          content="стоимость разработки, фиксированная стоимость, почасовая ставка, планирование проектов, Time and Material, фиксированная цена, веб-разработка, прозрачное управление, техническое задание, контроль затрат"
        />
      </Helmet>

      <div className='price_header_container'>
        <h1 >{t('price')}</h1>
        <div className='price_header_buttons_container'>
          <button className={`price_navButton ${priceType === 'priceFixOption' && 'active'}`} onClick={() => navigate('/priceFixOption')}>{t('fix')}</button>
          <button className={`price_navButton ${priceType === 'priceTimeOption' && 'active'}`} onClick={() => navigate('/priceTimeOption')}>{t('time')}</button>
        </div>
      </div>
      {priceType === 'priceFixOption' ?
        <PricePageContent pepIcon={PriceManPep} firstText={t('fixDesc')} info={[t('fix1Name'), t('fix1Desc'), t('fix2Name'), t('fix2Desc'), t('fix3Name'), t('fix3Desc'), t('fix4Name'), t('fix4Desc')]} />
        :
        <PricePageContent pepIcon={PriceGirlPep} firstText={t('timeDesc')} info={[t('time1Name'), t('time1Desc'), t('time2Name'), t('time2Desc'), t('time3Name'), t('time3Desc'), t('time4Name'), t('time4Desc')]} />
      }
    </div>
  )
};
