import { useState } from 'react'
import { useTranslation } from 'react-i18next'; 
import './index.css'
import Arrow from '../../../../shared/images/arrow.svg'
import { useNavigate } from 'react-router-dom';

export const ExpandedDiv = ({ service, price, text, biggerTitle }) => {
    const [isOpen, setIsOpen] = useState(false)
    const { t } = useTranslation(); 
    const navigate = useNavigate();

    const moduleDevRenderButton = service === t('moduleDev'); // Проверяем условие
    const moduleAdv1RenderButton = service === t('Adv1'); // Проверяем условие

    return (
        <div className="expandedDiv_container">
          <div
            className="expandedDiv_title"
            onClick={() => setIsOpen(!isOpen)}
          >
            <div className={biggerTitle ? 'expandedDiv_title_text_bigger' : 'expandedDiv_title_text'}>
              <p>{service}</p>
              <p>{price}</p>
            </div>
            <img
              alt="arrow img"
              src={Arrow}
              className={`${isOpen ? 'arrowDown' : ''}`}
              loading="lazy"
            />
          </div>
    
          {isOpen && (
            <div className="expandedDiv_text">
              <p
                dangerouslySetInnerHTML={{
                  __html: text,
                }}
              />
              {moduleDevRenderButton && (
                <div>
                  <br/>
                  <button className="button-text-green" onClick={() => { navigate('/notes/dev-vs-constructor');  window.scrollTo(0, 0)}}>
                    {t('moduleAboutButton')}
                  </button>
                </div>
              )}
              {moduleAdv1RenderButton && (
                <div>
                  <br/>
                  <button className="button-text-green" onClick={() => { navigate('/notes/seo-optimization-tips');  window.scrollTo(0, 0)}}>
                    {t('Adv1But')}
                  </button>
                </div>
              )}
            </div>
          )}
        </div>
      );
    };