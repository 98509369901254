import { useState, useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';
import './App.css';
import Header from './features/Header';
import { Footer } from './features/Footer';
import { HomePage } from './pages/Homepage';
import { PricePage } from './pages/PricePage';
import { SeoDescPage } from './pages/SeoDescPage';
import { Popup } from './features/Popup';
import { Cookies} from './features/Cookies'
import { WebVsConstructorPage } from './pages/WebVsConstructorPage'

import './i18n';
import { NotesListPage } from './pages/NotesListPage';

function App() {
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  useEffect(() => {
    if (isPopupOpen) {
      document.body.style.overflow = 'hidden'; // Отключить скролл
    } else {
      document.body.style.overflow = ''; // Восстановить скролл
    }

    // Очистка эффекта
    return () => {
      document.body.style.overflow = '';
    };
  }, [isPopupOpen]);

  return (
    <div className='App'>
      <Cookies />
      <Header />
      <Routes>
        <Route path='/' element={<HomePage />} />
        <Route path='/:priceType' element={<PricePage />} />
        <Route path='notes/dev-vs-constructor' element={<WebVsConstructorPage />} />
        <Route path='notes/seo-optimization-tips' element={<SeoDescPage/>} />
        <Route path='/notes' element={<NotesListPage/>} />
      </Routes>
      <Popup open={isPopupOpen} onClose={() => setIsPopupOpen(false)} />
      <Footer openPopup={() => setIsPopupOpen(true)} />
    </div>
  );
}

export default App;
