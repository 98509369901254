
import React, { useEffect } from "react";
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import "./index.css";
import { useNavigate } from "react-router-dom";

const changeLanguage = (lng, i18n, setActiveLanguage) => {
  console.log('Language changed to:', lng); // Логируем изменение
  i18n.changeLanguage(lng); // Меняем язык
  setActiveLanguage(lng); // Обновляем состояние активного языка
};

const scrollToSection = (id) => {
  if (window.location.pathname !== '/') {
    // Если не на главной странице, перенаправляем на главную
    window.location.href = `/#${id}`;
    return;
  }

  const element = document.getElementById(id);
  if (element) {
    const headerOffset = 100; // Высота вашего хедера в пикселях
    const elementPosition = element.getBoundingClientRect().top;
    const offsetPosition = elementPosition + window.scrollY - headerOffset;

    window.scrollTo({
      top: offsetPosition,
      behavior: 'smooth',
    });
  }
};

const Header = () => {
  const navigate = useNavigate()
  const { t, i18n } = useTranslation();
  const [phoneVersion, setPhoneVersion] = useState(window.innerWidth < 800);
  const [activeLanguage, setActiveLanguage] = useState(i18n.language);
  const [isNavOpen, setIsNavOpen] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      const isPhone = window.innerWidth < 800;
      if (isPhone !== phoneVersion) {
        setPhoneVersion(isPhone);
        window.location.reload();
      }
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [phoneVersion]);



  return (
    <div className="header_container">
      <div className="header_innerContsiner" itemscope itemtype="https://schema.org/WebSite">
        <div className="logo">
          <a href="/" itemprop="url">
            <span className="logo-symbol">&lt;/</span>
            <span className="logo-text" itemprop="name">LKeyStudio</span>
            <span className="logo-symbol">&gt;</span>
          </a>
        </div>
        {!phoneVersion &&
          <nav className="nav">
              <a
                href="/#services"
                className="nav-link"
                itemprop="url"
                onClick={(e) => {
                  e.preventDefault();
                  scrollToSection('services');
                }}
              >
                <span itemprop="name">{t('services')}</span>
              </a>
              <a
                href="/#price"
                className="nav-link"
                itemprop="url"
                onClick={(e) => {
                  e.preventDefault();
                  scrollToSection('price');
                }}
              >
                <span itemprop="name">{t('pricing')}</span>
              </a>
              <a
                href="/notes"
                className="nav-link"
                itemprop="url"
                onClick={(e) => {
                  e.preventDefault();
                  navigate('/notes');
                }}
              >
                <span itemprop="name">{t('notes')}</span>
              </a>
            </nav>
        }
            <div className="header-right">
              {!phoneVersion &&
                <button
                  className="colorfull-button"
                  onClick={() => scrollToSection('contact')}
                >
                  {t('contactUs')}
                </button>
              }
              <div className="language-switcher">
                <button
                  className={`language-button ${activeLanguage === 'ru' ? 'active' : ''}`}
                  onClick={() => changeLanguage('ru', i18n, setActiveLanguage)}
                >
                  RU
                </button>
                <span className="language-divider">|</span>
                <button
                  className={`language-button ${activeLanguage === 'en' ? 'active' : ''}`}
                  onClick={() => changeLanguage('en', i18n, setActiveLanguage)}
                >
                  EN
                </button>
                <span className="language-divider">|</span>
                <button
                  className={`language-button ${activeLanguage === 'zh' ? 'active' : ''}`}
                  onClick={() => changeLanguage('zh', i18n, setActiveLanguage)}
                >
                  中文
                </button>
              </div>
              {phoneVersion &&
                <button className='language-switcher menuBut' onClick={() => setIsNavOpen(!isNavOpen)}>МЕНЮ</button>
              }
              <div className={`phoneNav_container ${isNavOpen ? 'open' : 'closed'}`}>
              <nav className="nav">
              <a
                href="/#services"
                className="nav-link"
                itemprop="url"
                onClick={(e) => {
                  e.preventDefault();
                  scrollToSection('services');
                }}
              >
                <span itemprop="name">{t('services')}</span>
              </a>
              <a
                href="/#price"
                className="nav-link"
                itemprop="url"
                onClick={(e) => {
                  e.preventDefault();
                  scrollToSection('price');
                }}
              >
                <span itemprop="name">{t('pricing')}</span>
              </a>
              <a
                href="/notes/list"
                className="nav-link"
                itemprop="url"
                onClick={(e) => {
                  e.preventDefault();
                  navigate('/notes');
                }}
              >
                <span itemprop="name">{t('notes')}</span>
              </a>
            </nav>
              </div>
            </div>
          </div>
    </div>
      );
};

      export default Header;
