import './index.css'

export const StepCard = ({ title, text, icon }) => {
    return (
        <div className='stepCard_container'>
            <div className='stepCard_container_title'>
                <p
                    dangerouslySetInnerHTML={{
                        __html: title,
                    }}
                />
                <img alt='number icon' src={icon} loading="lazy"/>
            </div>
            <div className='stepCard_container_text'>
                <p
                    dangerouslySetInnerHTML={{
                        __html: text,
                    }}
                />
            </div>
        </div>
    )
}