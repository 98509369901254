import './index.css';
import { useTranslation } from 'react-i18next';
import { useState, useEffect } from 'react';

export const Info = () => {
  const { t } = useTranslation();

  const [tabletVersion, setTabletVersion] = useState(window.innerWidth < 1280);
  
  useEffect(() => {
    const handleResize = () => {
      const isTablet = window.innerWidth < 800;
      if (isTablet !== tabletVersion) {
        setTabletVersion(isTablet);
        window.location.reload();
      }
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [tabletVersion]);

  return (
    <div className='info_container'>
      <div className='info_email_section'>
        <p>{t('email')}</p>
        <a href="mailto:ask@lkeystudio.ru" itemprop='email'>ask@lkeystudio.ru</a>
      </div>
      <div className='info_ofice_section'>
        <p>{t('offices')}</p>
        <p>{t('russia')}</p>
        <div className='info_ofice_section_list'>
          <p itemprop='address'>{t('spb')}</p>
          <p itemprop='address'>{t('vdk')}</p>
        </div>
      </div>
      <div className='info_copyRight'>
        <p>© 2024 LKeyStudio</p>
        <p>Лаврова Альбина Юрьевна, {tabletVersion && <br/>} ИНН: 253800781081</p>
      </div>
    </div>
  );
};