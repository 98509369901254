import './index.css';
import { useTranslation } from 'react-i18next';

export const Popup = ({ open, onClose }) => {
  const { t } = useTranslation();

  return (
    <div className={`popup_container ${open && 'open'}`}>
      <div className='popup_innerContainer'>
        <p>{t('thank')}</p>
        <p>{t('waitForUs')}</p>
        <button className='colorfull-button' onClick={onClose}>{t('close')}</button>
      </div>
    </div>
  );
};
