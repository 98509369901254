import './index.css';

export const PriceCard = ({ title, text, icon }) => {
  return (
    <div className='priceCard_container'>
      <div className='priceCard_title'>
        <div className='priceCard_title_text'>
        <p
          dangerouslySetInnerHTML={{
              __html: title,
          }}
          />   
        </div>
        <img alt='price type img' src={icon} loading="lazy"/>
      </div>
      <div className='priceCard_text'>                    
        <p
          dangerouslySetInnerHTML={{
              __html: text,
          }}
          />   
        </div>
    </div>
  );
};
